import React, { useState, useEffect } from "react"

import axios from "axios"

import Layout from "../components/common/layout/layout"

const MaintenancePage = () => {
  const [headerConfigs, setHeaderConfigs] = useState(null)
  const [footerConfigs, setFooterConfigs] = useState(null)

  useEffect(() => {
    axios
      .get(`https://listener-strapy.thursday.social/header-configurations`)
      .then(response => {
        const responseData = response.data

        setHeaderConfigs(responseData)
      })

    axios
      .get(`https://listener-strapy.thursday.social/footer-configurations`)
      .then(response => {
        const responseData = response.data

        setFooterConfigs(responseData)
      })
  }, [])

  return (
    headerConfigs &&
    footerConfigs && (
      <Layout headerData={headerConfigs} footerData={footerConfigs}>
        <section className="hero-section">
          <div className="content-layer">
            <h1>
              The site is currently down for maintenance. We will be back soon
            </h1>
          </div>
        </section>
      </Layout>
    )
  )
}

export default MaintenancePage
